import ABDMActionNames from "../../action-names/nha/abdm.action-names"
import ABDMStateReference from "../../states-reference/nha/abdm.states-reference"


const INITIAL_STATE = {
    [ABDMStateReference.PATIENT_CREATE_ABHA]: {},
    [ABDMStateReference.PATIENT_CREATE_ABHA_AADHAAR_VERIFY]: {},
    [ABDMStateReference.PATIENT_LINK_ABHA]: {},
    [ABDMStateReference.PATIENT_LINK_ABHA_MOBILE_VERIFY]: {}
}


const ABDMReducer = (state = INITIAL_STATE, action) => {
    let key
    switch (action.type) {
        case ABDMActionNames.SAVE_PATIENT_CREATE_INFO:
            key = ABDMStateReference.PATIENT_CREATE_ABHA
            break
        case ABDMActionNames.SAVE_PATIENT_CREATE_AADHAAR_VERIFY:
            key = ABDMStateReference.PATIENT_CREATE_ABHA_AADHAAR_VERIFY
            break
        case ABDMActionNames.SAVE_PATIENT_LINK_INFO:
            key = ABDMStateReference.PATIENT_LINK_ABHA
            break
        case ABDMActionNames.SAVE_PATIENT_LINK_MOBILE_VERIFY:
            key = ABDMStateReference.PATIENT_LINK_ABHA_MOBILE_VERIFY
            break
        default:
            return state
    }

    return key ? { ...state, [key]: action.value } : state
}


export default ABDMReducer;