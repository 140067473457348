import { combineReducers } from "redux";
import abdmDetails from "./abdm.reducer";


const NHAReducer = combineReducers({
    abdmDetails
})


export default NHAReducer

