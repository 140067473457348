const ABDMStateReference = {
    PATIENT_CREATE_ABHA: "PATIENT_CREATE_ABHA",
    PATIENT_CREATE_ABHA_AADHAAR_VERIFY: "PATIENT_CREATE_ABHA_AADHAAR_VERIFY",
    PATIENT_LINK_ABHA: "PATIENT_LINK_ABHA",
    PATIENT_LINK_ABHA_MOBILE_VERIFY: "PATIENT_LINK_ABHA_MOBILE_VERIFY"
}


export const getABHAPatientCreateReference = (state) => (state.nhaDetails.abdmDetails[ABDMStateReference.PATIENT_CREATE_ABHA])


export const getABHAPatientCreateAadhaarVerify = (state) => (
    state.nhaDetails.abdmDetails[ABDMStateReference.PATIENT_CREATE_ABHA_AADHAAR_VERIFY])


export const getABHAPatientLinkReference = (state) => (state.nhaDetails.abdmDetails[ABDMStateReference.PATIENT_LINK_ABHA])


export const getABHAPatientLinkMobileVerify = (state) => (
    state.nhaDetails.abdmDetails[ABDMStateReference.PATIENT_LINK_ABHA_MOBILE_VERIFY])


export default ABDMStateReference;